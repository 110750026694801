<template>
  <div class="modal fade" id="amazon-associate-tag-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Amazon Associate Tag</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Only if you have one. We used it to create affiliate links</p>

          <div class="row">
            <div class="col-12">
              <input class="form-control form-control-sm"
                     :disabled="loading"
                     v-model="form.amazon_associate_tag"
                     placeholder="Tag"
                     type="text">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  :disabled="loading"
                  @click="submit">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: {
        amazon_associate_tag: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('amazon-associate-tag-modal'))
  },
  methods: {
    show(tag) {
      this.form.amazon_associate_tag = tag;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.loading = true;

      this.axios.post('/settings', this.form)
        .then(res => {
          this.$store.commit('settings/setSettings', res.data.data);
          this.hide();
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>